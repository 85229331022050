import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { fetchWrapper } from '../../utils/fetch-wrapper';
import { addCookie, deleteCookie } from '../../utils/cookies';

/**
 * Sets the auth session needed cookies based on the passed values.
 */
export const setAuthSession = ({
    accessToken,
    refreshToken,
    userPoolClientId
}: {
    accessToken: string;
    refreshToken: string;
    userPoolClientId: string;
}) => {
    addCookie('accessToken', accessToken, 60);
    addCookie('refreshToken', refreshToken);
    addCookie('userPoolClientId', userPoolClientId);
};

/**
 * Removes session cookies data
 */
export const removeAuthSession = () => {
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    deleteCookie('userPoolClientId');
};

const ctx = createContext<{
    user: undefined | { email: string; [key: string]: any };
    loading: boolean;
    login: (email: string, password: string) => void;
    logout: () => void;
}>({
    user: undefined,
    loading: true,
    login: () => null,
    logout: () => null
});

export const useUser = () => useContext(ctx);

export const UserProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    const getUser = async (retryOnInvalidToken = true) => {
        try {
            setLoading(true);

            if (
                !document.cookie.includes('accessToken') ||
                !document.cookie.includes('refreshToken')
            ) {
                throw new Error('No session');
            }

            const res = await fetchWrapper.get('/api/user/profile');
            const { email, user_id, name } = res;
            setUser(res);
            Sentry.setUser({ email, username: name, id: user_id });
        } catch (e: any) {
            // Try to refresh token and refetch profile
            if (retryOnInvalidToken && e.status === 401 && e.message === 'Invalid token') {
                console.log('Invalid session. Trying to refresh token...');
                await refreshAccessToken();
            } else {
                resetUserContext();
            }
        } finally {
            setLoading(false);
        }
    };

    const resetUserContext = () => {
        console.log('Clearing user session...');
        removeAuthSession();

        setUser(undefined);
        Sentry.configureScope((scope) => scope.setUser(null));
    };

    useEffect(() => {
        // Fetch only if there's no data
        if (!user) {
            getUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = useCallback(async (email, password) => {
        setLoading(true);
        try {
            const { accessToken, refreshToken, userPoolClientId } = await fetchWrapper.post(
                '/api/user/login',
                { email, password }
            );

            setAuthSession({
                accessToken,
                refreshToken,
                userPoolClientId
            });

            // Fetch the profile for the app to fill it
            await getUser();
        } catch (e: any) {
            throw new Error(e.message || 'Unknown error');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = useCallback(async () => {
        resetUserContext();
    }, []);

    const refreshAccessToken = async () => {
        try {
            const { accessToken, refreshToken, userPoolClientId } = await fetchWrapper.post(
                '/api/user/refresh-token'
            );
            console.log('Refreshing access token', { accessToken, refreshToken });

            setAuthSession({
                accessToken,
                refreshToken,
                userPoolClientId
            });

            // Refetch profile but don't retry on invalid token
            await getUser(false);
        } catch (e: any) {
            // On error reset context to redirect to login
            console.log('Failed to refresh token', e.message);
            resetUserContext();
        }
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            loading
        }),
        [user, loading, login, logout]
    );

    return <ctx.Provider value={value}>{children}</ctx.Provider>;
};
