export const cookies = (value = '') => {
    return value.split(';').reduce((out: any, line) => {
        const [key, value] = line.split('=');
        out[key.trim()] = value;
        return out;
    }, {});
};

export const deleteCookie = (name: string) => {
    document.cookie = name + '=; path=/; Max-Age=0;';
};

export const addCookie = (name: string, value: string, expiresAtMinutes?: number) => {
    const expiresAt = expiresAtMinutes
        ? `; expiresAt= ${new Date(Date.now() + expiresAtMinutes * 60 * 1000).toUTCString()}`
        : '';
    document.cookie = `${name}=${value}${expiresAt}; path=/`;
};
