/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/dist/client/router';
import React from 'react';
import Logo from '../assets/svgs/logo.svg';
import Avatar from '../assets/svgs/avatar.svg';
import { useUser } from '../hooks/user/user';
import { Link } from './link';
import { Menu } from '@headlessui/react';

const Layout: React.FC = ({ children }) => {
    const { user, logout } = useUser();
    const router = useRouter();

    return (
        <div className="flex flex-col w-full h-full">
            <div className="p-8 border-b-2 w-full flex flex-row items-center justify-between">
                <div className="flex items-center">
                    <img
                        src={Logo.src}
                        width={43}
                        height={40}
                        alt="Magic Account"
                        className="mr-4"
                    />
                    <h1 className="text-2xl md:text-3xl text-magic-purple-light font-semibold">
                        <b className="font-bold mr-1 text-magic-purple">Magic</b>
                        Account
                    </h1>
                </div>
                <nav className="md:space-x-16 z-10">
                    {user ? (
                        <Menu>
                            <Menu.Button>
                                <img src={Avatar.src} width="40" height="40" alt="Menu" />
                            </Menu.Button>
                            <Menu.Items className="flex flex-col overflow-hidden w-48 absolute right-0 mt-2 mr-2 origin-top-right bg-white border border-gray border-opacity-40 rounded-md shadow-lg">
                                {!user.privileges?.admin ? (
                                    <Menu.Item>
                                        <Link
                                            label="Home"
                                            href="/"
                                            className="hover:bg-neutral-gray active:bg-neutral-gray-50 px-4 py-2"
                                        />
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item>
                                        <Link
                                            label="Admin Dashboard"
                                            href="/admin"
                                            className="hover:bg-neutral-gray active:bg-neutral-gray-50 px-4 py-2"
                                        />
                                    </Menu.Item>
                                )}
                                <Menu.Item>
                                    <Link
                                        label="Logout"
                                        href="/logout"
                                        className="hover:bg-neutral-gray active:bg-neutral-gray-50 px-4 py-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            logout();
                                            router.reload();
                                        }}
                                    />
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    ) : null}
                </nav>
            </div>
            {children}
        </div>
    );
};

export default Layout;
