import 'tailwindcss/tailwind.css';
import '../styles/reset.css';
import '../styles/fonts.css';
import '../styles/main.css';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import Layout from '../src/components/layout';
import { UserProvider } from '../src/hooks/user/user';

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Magic Account</title>
                <meta name="description" content="Magic Account" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Head>
            <UserProvider>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </UserProvider>
            <Toaster />
        </>
    );
}

export default MyApp;
