import { useRouter } from 'next/dist/client/router';
import NextLink from 'next/link';
import React from 'react';

export const Link: React.FC<{
    label: string;
    href: string;
    className?: string;
    showActiveState?: boolean;
    onClick?: (e: any) => void;
}> = ({ label, showActiveState, className, onClick, ...props }) => {
    const router = useRouter();
    const isActive = router.pathname === props.href;
    const activeClassName =
        showActiveState && isActive
            ? 'bg-magic-purple-light py-2 px-8 text-white rounded-3xl'
            : 'text-magic-purple-light';
    return (
        <NextLink passHref {...props}>
            <a className={`${className || activeClassName}`} onClick={onClick}>
                {label}
            </a>
        </NextLink>
    );
};
