import { cookies } from './cookies';

export const fetchWrapper = {
    get,
    post
};

const authHeader = (headers?: any): { Authorization?: any } => {
    if (headers) {
        return headers;
    }

    const { accessToken } = cookies(document.cookie);
    if (accessToken) {
        return { Authorization: `Bearer ${accessToken}` };
    }
    return {};
};

async function get(url: string, auth?: any) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(auth)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

async function post(url: string, body?: any, auth?: any) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(auth) },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const errorMessage = (data && data.message) || data || response.statusText;
            return Promise.reject({ message: errorMessage, status: response.status });
        }

        return data;
    });
}
